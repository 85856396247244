import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import { ImLocation } from "react-icons/im";
import { BsTelephoneFill, BsLinkedin, BsFacebook } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="bg-darkbrown w-full flex flex-col items-center">
        <div className="container  px-4 py-8 ">
          <h1 className="text-center text-xl text-white ">
            BCS is a Woman-Owned Small Business
          </h1>
          <hr className="mb-4 mt-8 border-white/[0.7] border-bw-full"></hr>
          <div className="flex flex-row flex-wrap justify-start">
            <img
              className="d-none d-lg-block mx-6 lg:mx-8 opacity-80"
              src={logo}
              alt="title"
              style={{
                width: 175,
              }}
            ></img>
            <div className="flex flex-col p-5 mx-6 lg:mx-8">
              <p className="text-white  mb-2">Contact</p>
              <p className="text-white/[0.6] mb-1">
                <ImLocation className="inline mr-2 text-lg" />
                7202 Poplar Street, Suite F{" "}
                <p className="ml-6">Annandale, VA 22003 </p>
              </p>
              <a
                className="text-white/[0.6] mb-1  hover:text-white/[0.8]"
                href="tel:7038138901"
              >
                <BsTelephoneFill className="inline mr-2"></BsTelephoneFill>(703)
                813-8901{" "}
              </a>
              <a
                href="mailto:info@bcservices-inc.com"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                <GrMail className="inline mr-2" />
                info@bcservices-inc.com
              </a>
            </div>
            <div className="flex flex-col p-5 mx-6 lg:mx-8">
              <p className="text-white mb-2">About</p>
              <Link
                to="/about"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                About Us
              </Link>
              <Link
                to="/about/team"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                The Team
              </Link>
              <Link
                to="/about/#message"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                President's Message
              </Link>
              <Link
                to="/about/#market"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                Markets Served
              </Link>
            </div>
            <div className="flex flex-col p-5 mx-6 lg:mx-8">
              <Link className="text-white mb-2">Info</Link>
              <Link
                to="/capabilities"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                Capabilities
              </Link>
              <Link
                to="/projects"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                Projects
              </Link>
              <Link
                to="/pricing"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                Pricing
              </Link>
              <Link
                to="/careers"
                className="text-white/[0.6] hover:text-white/[0.8] mb-1"
              >
                Careers
              </Link>
            </div>
            <div className="flex flex-col p-5 mx-6 lg:mx-8">
              <p className="text-white mb-2">Follow Us</p>
              <div className="flex flex-row">
                <a
                  href="https://www.linkedin.com/company/brown-construction-services-inc-/?viewAsMember=true"
                  target="_blank"
                  className="text-white/[0.6] hover:text-white/[0.8] text-2xl mr-3 mb-1"
                >
                  <BsLinkedin />
                </a>
                <a
                  href="https://www.facebook.com/Brown-Construction-Services-Inc-367052696719698/?epa=SEARCH_BOX"
                  className="text-white/[0.6] hover:text-white/[0.8] text-2xl mx-3 mb-1"
                >
                  <BsFacebook />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
