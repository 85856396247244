import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import logo from "../img/logo.svg";
import logo_textOnly from "../img/logo_textOnly.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
export default function Navbar({ location }) {
  const [activePath, setActivePath] = useState("home");
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const pathname = location?.pathname || ""
    setActivePath(getActivePath(pathname));
    setCollapsed(true);
  }, [location]);

  const [navScroll, setNavScroll] = useState(
    !(location && location.pathname === "/")
  );
  const isDesktop = useMediaQuery("only screen and (min-width: 992px)");
  const navRef = useRef();
  navRef.current = navScroll;
  useEffect(() => {
    if (location && location.pathname === "/") {
      setNavScroll(false);
      const handleScroll = () => {
        const show = window.scrollY > 50;
        if (navRef.current !== show) {
          setNavScroll(show);
        }
      };
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    } else {
      setNavScroll("show");
    }
  }, [location]);

  const getActivePath = (pathname) => {
    if (pathname === "/") {
      return "home";
    } else if (pathname.startsWith("/about")) {
      return "about";
    } else if (pathname.startsWith("/pricing")) {
      return "pricing";
    } else if (pathname.startsWith("/projects")) {
      return "projects";
    } else if (pathname.startsWith("/careers")) {
      return "careers";
    } else if (pathname.startsWith("/capabilities")) {
      return "capabilities";
    } else if (pathname.startsWith("/contact")) {
      return "contact";
    } else {
      return "home";
    }
  };

  return (
    <nav className="fixed w-full text-white z-40">
      <div
        className={`w-full h-fit flex px-3 py-2 items-center justify-center transition ease-in-out duration-500 ${
          navScroll || !isDesktop ? "bg-navy shadow-lg" : "transparent"
        }`}
      >
        <div className="container overflow-hidden flex flex-col md:flex-row justify-between  items-start md:items-center p-3">
          <div className="flex w-full flex-row justify-between items-center">
            <Link to="/" className="me-3">
              <div>
                <img
                  className="d-none d-lg-block"
                  src={logo}
                  alt="title"
                  style={{
                    transition: "width .7s ease, opacity 1s ease",
                    width: navScroll || !isDesktop ? 0 : 175,
                    opacity: navScroll || !isDesktop ? 0 : 1,
                  }}
                ></img>
                <img
                  className="d-none d-lg-block"
                  src={logo_textOnly}
                  alt="title"
                  style={{
                    transition: "width .5s ease, opacity 1s ease",
                    width: navScroll || !isDesktop ? 75 : 0,
                    opacity: navScroll || !isDesktop ? 1 : 0,
                    margin: 5,
                    marginBottom: 8,
                  }}
                ></img>
              </div>
            </Link>
            <button
              className="md:hidden"
              onClick={() => {
                setCollapsed((current) => !current);
              }}
            >
              {collapsed ? (
                <GiHamburgerMenu className="text-white/[0.8] text-3xl" />
              ) : (
                <MdClose className="text-white/[0.8] text-3xl" />
              )}
            </button>
          </div>
          <ul
            className={`${
              collapsed ? "h-0" : "my-6 h-fit"
            } flex flex-col md:flex-row md:my-0 transition-height transition ease-in-out duration-500 items-center `}
          >
            <NavLink activeKey="home" to="/" activePath={activePath}>
              Home
            </NavLink>
            <NavLink activeKey="about" to="/about" activePath={activePath}>
              About
            </NavLink>
            <NavLink
              activeKey="capabilities"
              to="/capabilities"
              activePath={activePath}
            >
              Capabilities
            </NavLink>
            <NavLink
              activeKey="projects"
              to="/projects"
              activePath={activePath}
            >
              Projects
            </NavLink>
            <NavLink activeKey="pricing" to="/pricing" activePath={activePath}>
              Pricing
            </NavLink>
            <NavLink activeKey="careers" to="/careers" activePath={activePath}>
              Careers
            </NavLink>
            <NavLink activeKey="contact" to="/contact" activePath={activePath}>
              Contact
            </NavLink>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function NavLink({ activeKey, activePath, to, children }) {
  const active = activePath === activeKey;
  return (
    <li className="w-full my-2 md:my-0">
      <Link to={to}>
        <div
          className={` text-left md:text-center mx-3 ${
            active ? " text-white" : "text-white/[0.7]"
          }`}
        >
          {children}
        </div>
      </Link>
    </li>
  );
}
